.App {
  text-align: center;
}

body, html, #root {
  height: 100%;
}

.btn-primary {
  background-color: #001d41 !important;
  border: 1px solid #001d41 !important;
}

.btn-success {
  background-color: #001d41 !important;
  border: 1px solid #001d41 !important;
}

.btn-secondary {
  background-color: #001d41 !important;
  border: 1px solid #001d41 !important;
}

.wallpaper {
  background: url(./assets/wallpaper.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

.list-group-item.active {
  background-color: #f67b2f !important;
  border: 1px solid #f67b2f !important;
}
