.container {
  border: 1px #c7c7c7 solid;
  box-shadow: -5px -1px 5px #f1f1f1;
  width: 400px;
}

.padded {
  padding: 16px 32px;
}

.title {
  color: #444444;
  font-size: 32px;
}

.subtext {
  color: #999999;
}
